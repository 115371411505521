import { getInitialsFromName } from '@pasqal/core/helpers/data';
import { getClasses } from '@pasqal/core/helpers/styles';
import type { TPerson } from '@pasqal/core/types';
import type { TSize } from '@pasqal/core/ui/components/types';

import '@pasqal/core/ui/components/avatar/Avatar/avatar.css';

type TAvatarVariant = 'circle' | 'box';
type TAvatarSize = Extract<TSize, 'sm' | 'md' | 'lg' | 'xl' | '2xl'>;

interface IAvatarProps {
  person?: TPerson;
  className?: string;
  content?: string;
  variant?: TAvatarVariant;
  size?: TAvatarSize;
  testId?: string;
}

interface IStyle {
  variant: Record<TAvatarVariant, string>;
  size: Record<TAvatarSize, string>;
}

const styles: IStyle = {
  variant: {
    circle: 'Avatar--circle',
    box: 'Avatar--box'
  },
  size: {
    sm: 'Avatar--sm',
    md: 'Avatar--md',
    lg: 'Avatar--lg',
    xl: 'Avatar--xl',
    '2xl': 'Avatar--2xl'
  }
};

export const Avatar = ({
  person,
  className,
  variant = 'circle',
  content = '',
  size = 'md',
  testId
}: IAvatarProps) => {
  const css = getClasses([
    'Avatar',
    styles.variant[variant],
    styles.size[size],
    className
  ]);

  const initials = person ? getInitialsFromName(person) : content;

  return <div className={css} data-initials={initials} data-testid={testId} />;
};
